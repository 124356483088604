@font-face {
    font-family: "ballet";
    src: local("ballet"), url(../src/utils/fonts/ballet/Ballet_48pt/Ballet_48pt-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "quattrocento";
    src: local("quattrocento"), url(../src/utils/fonts/Quattrocento/Quattrocento-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "quattrocento-bold";
    src: local("quattrocento"), url(../src/utils/fonts/Quattrocento/Quattrocento-Bold.ttf) format("truetype");
}

body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h1 {
    font-family: "ballet";
    letter-spacing: 0px;
}
h2,
button {
    font-family: "quattrocento-bold";
}

h3,
p,
li,
a {
    font-family: "quattrocento";

    /* letter-spacing: 0px; */
}

h1,
h2,
h3,
h4,
p,
button,
li,
ol,
ul,
a {
    margin: 0;
    padding: 0;
}

.home-intro {
    background-image: url(../src/utils/images/proposalphotosbarcelona-b-67.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    background-color: #f1f5f260;
    background-blend-mode: overlay;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* border: 1px solid red; */
}

.rsvp-button {
    width: 200px;
    padding: 10px;
    font-size: 20px;
    justify-self: baseline;
    color: #f1f5f2;
    justify-self: baseline;
    cursor: pointer;
    background-color: #092b22;
    border: 1px solid transparent;
}

.rsvp {
    background-image: url(../src/utils/images/banthonyprod_watercolor_leaf_wreath_on_a_white_background_in_th_39f5cdd5-9ff7-417f-92d9-281fe6234a1d.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #f1f5f260;
    background-blend-mode: overlay;
}

.next-button {
    width: 100px;
    padding: 10px;
    font-size: 20px;
    color: #f1f5f2;
    justify-self: baseline;
    cursor: pointer;
    background-color: #092b22;
    border: 1px solid transparent;
}

.rsvp-button:hover,
.next-button:hover {
    transition: 0.4s;
    color: #092b22;
    background-color: white;
    border: 1px solid #092b22;
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.loading {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
}

.circle {
    width: 100px;
    height: 100px;
    margin: 110px auto 0;
    border: solid 10px #8822aa;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    /* transform: rotate(-90deg); to start the circle from the top */
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7); */
    }

    70% {
        transform: scale(1);
        /* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
    }

    100% {
        transform: scale(0.95);
        /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    }
}

.castle {
    transform: scale(1);
    animation: pulse 2s 3000;
}

.loading-enter {
    opacity: 1;
}

.loading-enter-active {
    opacity: 1;
    transition: opacity 3000ms;
}

.loading-exit {
    opacity: 1;
}

.loading-exit-active {
    opacity: 0;
    transition: opacity 3000ms;
}

.nav-item {
    padding: 5px 0;
}

.nav-item:hover {
    transition: 0.2s;
    border-bottom: 2px solid black;
}

.deny-button {
    margin: 20px 0;
    width: 25%;
    padding: 10px;
    font-size: 20px;
    color: #092b22;
    cursor: pointer;
    background-color: #f1f5f2;
    border: 1px solid #092b22;
}

.deny-button:hover {
    transition: 0.4s;
    color: white;
    background-color: #092b22;
    border: 1px solid #092b22;
}

.hammond-title {
    font-size: 150px;
    font-family: "ballet";
    letter-spacing: 0px;
}

.michaela-brent-title {
    font-size: 70px;
    font-family: "ballet";
    letter-spacing: 0px;
}

@media screen and (min-width: 1060px) and (max-width: 1499px) {
    .michaela-brent-title,
    .hammond-title {
        font-size: 50px;
        font-family: "ballet";
        letter-spacing: 0px;
    }
}

@media screen and (max-width: 1060px) {
    .michaela-brent-title,
    .hammond-title {
        font-size: 40px;
        font-family: "ballet";
        letter-spacing: 0px;
    }
}
